<template>
  <sdUserCard>
      <sdCards title="Product">
        <div class="card productRevision-card">
        <a-skeleton v-if="!order || !order.productRevision" active/>
        <template v-else>
          <figure>
            <img :src="`${order.productRevision.imageFileURL}`" alt="" style="max-width: 200px"/>
          </figure>
          <figcaption>
            <div class="card__content mt-10" style="color:rgb(39,43,65);">
              <sdHeading as="h6">
                {{ order.productRevision.name }}
              </sdHeading>
            </div>
          </figcaption>
        </template>
        </div>
      </sdCards>
  </sdUserCard>
</template>
<script>
import {defineComponent} from 'vue';
import VueTypes from "vue-types";

const ProductInfoCard = defineComponent({
  name: 'ProductInfoCard',
  props: {
    order: VueTypes.object.def(null),
  },
});

export default ProductInfoCard;
</script>
