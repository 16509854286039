<template>
 <Suspense>
  <sdCards v-if="!calculations && !calculations.calculation && calculations.calculation.connectionCalculations"
           title="Kostenspecificatie">
   <a-skeleton active/>
  </sdCards>
  <sdCards v-else title="Kostenspecificatie">
   <a-collapse v-for="(item, index) in calculations.calculation.connectionCalculations"
               :key="index" :bordered="false" default-active-key="0">
    <template #expandIcon="props">
     <sdFeatherIcons :type="props.isActive ? 'chevron-down' : 'chevron-right'" size="14"/>
    </template>
    <a-collapse-panel key="1">
     <template #header>
      <div class="lead collapse-title" style="width:100%;display:inline-block;">
       <i v-if="item.connection.marketSegment === 'ELECTRICITY'" class="fas fa-bolt"
          style="float:left;font-size: 16px; color:#000000"></i>
       <i v-if="item.connection.marketSegment === 'GAS'" class="fas fa-burn"
          style="float:left;font-size: 16px; color:#000000"></i>
       <h4 class="ml-3 mr-1" style="float:left">
        {{
         item.connection.marketSegment === 'ELECTRICITY' ? 'Stroom' : ''
        }}{{ item.connection.marketSegment === 'GAS' ? 'Gas' : '' }}:
        {{ item.connection.ean }}</h4>
       <div class="text-right mr-5"></div>
      </div>
     </template>
     <div>
      <hr/>
      <div>
       {{ calculations.product.name }}
      </div>
      <hr/>
      <a-row>
       <a-col
        :xs="24"
        class="mt-1"
       >
        <strong>Leveringskosten</strong>
       </a-col>
       <section
        v-if="item.connection.meterType === 'SINGLE' && item.connection.marketSegment === 'ELECTRICITY'"
        style="width:100%;display: flex"
       >
        <a-col
         :xs="16"
        >
         Leveringskosten Enkeltarief <i><small>({{ item.calculation.usage.consumption.single.usage }}
         kWh)</small></i>
        </a-col>
        <a-col
         :xs="8"
         class="text-right"
        >
                              <span
                               v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                parseFloat(item.calculation.usage.consumption.single.cost * 1.21).toFixed(2)
                               }}</span>
         <span
          v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
           parseFloat(item.calculation.usage.consumption.single.cost).toFixed(2)
          }}</span>
        </a-col>
       </section>
       <section
        v-if="item.connection.marketSegment === 'GAS'"
        style="width:100%;display: flex"
       >
        <a-col
         :xs="16"
        >
         Leveringskosten <i><small>({{ item.calculation.usage.consumption.single.usage }} m3)</small></i>
        </a-col>
        <a-col
         :xs="8"
         class="text-right"
        >
                              <span
                               v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                parseFloat(item.calculation.usage.consumption.single.cost * 1.21).toFixed(2)
                               }}</span>
         <span
          v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
           parseFloat(item.calculation.usage.consumption.single.cost).toFixed(2)
          }}</span>
        </a-col>
       </section>
       <section
        v-if="item.connection.meterType === 'DOUBLE'"
        style="width:100%;display: flex"
       >
        <a-col
         :xs="16"
        >
         Leveringskosten Normaal <i><small>{{ item.calculation.usage.consumption.high.usage }} kWh</small></i>
        </a-col>
        <a-col
         :xs="8"
         class="text-right"
        >
                              <span
                               v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                parseFloat(item.calculation.usage.consumption.high.cost * 1.21).toFixed(2)
                               }}</span>
         <span
          v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
           parseFloat(item.calculation.usage.consumption.high.cost).toFixed(2)
          }}</span>
        </a-col>
       </section>
       <section
        v-if="item.connection.meterType === 'DOUBLE'"
        style="width:100%;display: flex"
       >
        <a-col
         :xs="16"
        >
         Leveringskosten Dal <i><small>{{ item.calculation.usage.consumption.low.usage }} kWh</small></i>
        </a-col>
        <a-col
         :xs="8"
         class="text-right"
        >
                              <span
                               v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                parseFloat(item.calculation.usage.consumption.low.cost * 1.21).toFixed(2)
                               }}</span>
         <span
          v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
           parseFloat(iitem.calculation.usage.consumption.low.cost).toFixed(2)
          }}</span>
        </a-col>
       </section>

       <section
        v-if="item.connection.meterType === 'SINGLE' && item.connection.marketSegment === 'ELECTRICITY' && item.calculation.usage.return.total !== 0"
        style="width:100%;display: flex"
       >
        <a-col
         :xs="16"
        >
         Teruglevering Enkel <i><small>({{ item.calculation.usage.return.single.usage }} kWh)</small></i>
        </a-col>
        <a-col
         :xs="8"
         class="text-right"
        >
                              <span
                               v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                parseFloat(item.calculation.usage.return.single.cost * 1.21).toFixed(2)
                               }}</span>
         <span
          v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
           parseFloat(item.calculation.usage.return.single.cost).toFixed(2)
          }}</span>
        </a-col>
       </section>
       <section
        v-if="item.connection.meterType === 'DOUBLE' && item.calculation.usage.return.total !== 0"
        style="width:100%;display: flex"
       >
        <a-col
         :xs="16"
        >
         Teruglevering Normaal <i><small>{{ item.calculation.usage.return.high.usage }} kWh</small></i>
        </a-col>
        <a-col
         :xs="8"
         class="text-right"
        >
                              <span
                               v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                parseFloat(item.calculation.usage.return.high.cost * 1.21).toFixed(2)
                               }}</span>
         <span
          v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
           parseFloat(item.calculation.usage.return.high.cost).toFixed(2)
          }}</span>
        </a-col>
       </section>
       <section
        v-if="item.connection.meterType === 'DOUBLE' && item.calculation.usage.return.total !== 0"
        style="width:100%;display: flex"
       >
        <a-col
         :xs="16"
        >
         Teruglevering Dal <i><small>{{ item.calculation.usage.return.low.usage }} kWh</small></i>
        </a-col>
        <a-col
         :xs="8"
         class="text-right"
        >
                              <span
                               v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                parseFloat(item.calculation.usage.return.low.cost * 1.21).toFixed(2)
                               }}</span>
         <span
          v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
           parseFloat(iitem.calculation.usage.return.low.cost).toFixed(2)
          }}</span>
        </a-col>
       </section>
       <section
        style="width:100%;display: flex"
       >
        <a-col
         :xs="16"
        >
         Vaste leveringskosten
        </a-col>
        <a-col
         :xs="8"
         class="text-right"
        >
                              <span
                               v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                parseFloat(item.calculation.fixed * 1.21).toFixed(2)
                               }}</span>
         <span
          v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
           parseFloat(item.calculation.fixed).toFixed(2)
          }}</span>
        </a-col>
       </section>
       <section
        v-if="item.calculation.fixedReturn"
        style="width:100%;display: flex"
       >
        <a-col
         :xs="16"
        >
         Vaste terugleveringskosten
        </a-col>
        <a-col
         :xs="8"
         class="text-right"
        >
                              <span
                               v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                parseFloat(item.calculation.fixedReturn * 1.21).toFixed(2)
                               }}</span>
         <span
          v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
           parseFloat(item.calculation.fixedReturn).toFixed(2)
          }}</span>
        </a-col>
       </section>
       <a-col
        :xs="24"
        class="mt-1"
       >
        <strong>Netbeheerkosten</strong>
       </a-col>
       <a-col
        :xs="18"
       >
        Netbeheerkosten
       </a-col>
       <a-col
        :xs="6"
        class="text-right"
       >
                              <span
                               v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                parseFloat(item.calculation.grid.total * 1.21).toFixed(2)
                               }}</span>
        <span
         v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
          parseFloat(item.calculation.grid.total).toFixed(2)
         }}</span>
       </a-col>
       <a-col
        :xs="24"
        class="mt-1"
       >
        <strong>Belasting en toeslagen</strong>
       </a-col>
       <section
        v-for="energyTaxItem in item.calculation.tax"
        :key="energyTaxItem.componentType + String(Math.random())"
        style="width:100%;display: flex"
       >
        <a-col
         :xs="18"
        >
         Energiebelasting <small>({{ energyTaxItem.from }} - {{ energyTaxItem.to }} &euro; {{
          energyTaxItem.price
         }}</small>)
        </a-col>
        <a-col
         :xs="6"
         class="text-right"
        >
                              <span
                               v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                parseFloat(energyTaxItem.cost * 1.21).toFixed(2)
                               }}</span>
         <span
          v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
           parseFloat(energyTaxItem.cost).toFixed(2)
          }}</span>
        </a-col>
       </section>
       <section
        v-for="energyTaxItem in item.calculation.ode"
        :key="energyTaxItem.componentType + String(Math.random())"
        style="width:100%;display: flex"
       >
        <a-col
         :xs="18"
        >
         Opslag duurzame energie (<small>{{ energyTaxItem.from }} - {{ energyTaxItem.to }}
         &euro; {{ energyTaxItem.price }}</small>)
        </a-col>
        <a-col
         :xs="6"
         class="text-right"
        >
                              <span
                               v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                parseFloat(energyTaxItem.cost * 1.21).toFixed(2)
                               }}</span>
         <span
          v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
           parseFloat(energyTaxItem.cost).toFixed(2)
          }}</span>
        </a-col>
       </section>
       <section
        v-if="item.calculation.reduction !== 0"
        style="width:100%;display: flex"
       >
        <a-col
         :xs="18"
        >
         Vermindering energiebelasting
        </a-col>
        <a-col
         :xs="6"
         class="text-right"
        >
                              <span
                               v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                parseFloat(item.calculation.reduction * 1.21).toFixed(2)
                               }}</span>
         <span
          v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
           parseFloat(item.calculation.reduction).toFixed(2)
          }}</span>
        </a-col>
       </section>
       <a-col
        :xs="24"
       >
        <hr>
       </a-col>
       <a-col
        :xs="16"
       >
        <strong>Totaal (per jaar)</strong>
       </a-col>
       <a-col
        :xs="8"
        class="text-right"
       >
                            <span
                             v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                              parseFloat(item.calculation.total * 1.21).toFixed(2)
                             }}</span>
        <span
         v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
          parseFloat(item.calculation.total).toFixed(2)
         }}</span>
       </a-col>
      </a-row>
     </div>
    </a-collapse-panel>
   </a-collapse>
   <a-collapse :bordered="false" default-active-key="0">
    <template #expandIcon="props">
     <sdFeatherIcons :type="props.isActive ? 'chevron-down' : 'chevron-right'" size="14"/>
    </template>
    <a-collapse-panel key="1">
     <template #header>
      <div class="lead collapse-title" style="width:100%;display:inline-block;">
       <i class="fas fa-euro-sign" style="float:left;font-size: 16px; color:#000000"></i>
       <h4 class="ml-3 mr-1" style="float:left">Tarieven</h4>
      </div>
     </template>
     <div>
      <a-row>
       <a-col :xs="12" class="mt-1">
        &nbsp;
       </a-col>
       <a-col :xs="4">
        <span><small>Excl.</small></span>
       </a-col>
       <a-col :xs="4">
        <span><small>Incl.</small></span>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>Incl. ODE & EB</small></span>
       </a-col>
       <a-col
        :xs="24"
        class="mt-1"
       >
        <strong>Elektriciteit</strong>
       </a-col>
       <a-col
        :xs="12"
        style="margin-top:5px"
       >
        Enkeltarief <small><i>(per kWh)</i></small>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withoutVat?.tariffSingle }}</small></span>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withVat?.tariffSingle }}</small></span>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withTotalVat?.tariffSingle }}</small></span>
       </a-col>
       <a-col
        :xs="12"
       >
        Normaaltarief <small><i>(per kWh)</i></small>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withoutVat?.tariffHigh }}</small></span>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withVat?.tariffHigh }}</small></span>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withTotalVat?.tariffHigh }}</small></span>
       </a-col>
       <a-col
        :xs="12"
       >
        Daltarief <small><i>(per kWh)</i></small>
       </a-col>
       <a-col :xs="4"><span><small>&euro;{{ calculations?.consumerTariff?.withoutVat?.tariffLow }}</small></span>
       </a-col>
       <a-col :xs="4"><span><small>&euro;{{ calculations?.consumerTariff?.withVat?.tariffLow }}</small></span></a-col>
       <a-col :xs="4"><span><small>&euro;{{ calculations?.consumerTariff?.withTotalVat?.tariffLow }}</small></span>
       </a-col>
       <a-col :xs="12">Teruglevering enkeltarief <small><i>(per kWh)</i></small></a-col>
       <a-col :xs="4"><span><small>&euro;{{
         calculations?.consumerTariff?.withoutVat?.tariffReturnSingle
        }}</small></span></a-col>
       <a-col :xs="4"><span><small>&euro;{{ calculations?.consumerTariff?.withVat?.tariffReturnSingle }}</small></span>
       </a-col>
       <a-col :xs="4"><span><small>&euro;{{
         calculations?.consumerTariff?.withTotalVat?.tariffReturnSingle
        }}</small></span>
       </a-col>
       <a-col
        :xs="12"
       >
        Teruglevering normaaltarief <small><i>(per kWh)</i></small>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withoutVat?.tariffReturnSingle }}</small></span>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withVat?.tariffReturnSingle }}</small></span>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withTotalVat?.tariffReturnSingle }}</small></span>
       </a-col>
       <a-col
        :xs="12"
       >
        Teruglevering daltarief <small><i>(per kWh)</i></small>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withoutVat?.tariffReturnLow }}</small></span>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withVat?.tariffReturnLow }}</small></span>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withTotalVat?.tariffReturnLow }}</small></span>
       </a-col>
       <a-col
        :xs="12"
       >
        Vaste leveringskosten <small><i>(per jaar)</i></small>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withoutVat?.tariffFixedEnergy }}</small></span>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withVat?.tariffFixedEnergy }}</small></span>
       </a-col>
       <a-col
        :xs="4"
       >
        <span>&nbsp;</span>
       </a-col>

       <a-col
        v-if="calculations.consumerTariff.withoutVat.tariffFixedEnergyReturn"
        :xs="12"
       >
        Vaste terugleveringskosten <small><i>(per jaar)</i></small>
       </a-col>
       <a-col
        v-if="calculations.consumerTariff.withoutVat.tariffFixedEnergyReturn"
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withoutVat?.tariffFixedEnergyReturn }}</small></span>
       </a-col>
       <a-col
        v-if="calculations.consumerTariff.withoutVat.tariffFixedEnergyReturn"
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withVat?.tariffFixedEnergyReturn }}</small></span>
       </a-col>
       <a-col
        v-if="calculations.consumerTariff.withoutVat.tariffFixedEnergyReturn"
        :xs="4"
       >
        <span>&nbsp;</span>
       </a-col>
      </a-row>
      <a-row>
       <a-col
        :xs="24"
        class="mt-1"
       >
        <strong>Gas</strong>
       </a-col>
       <a-col
        :xs="12"
        style="margin-top:5px"
       >
        Leveringstarief (G1) <small><i>(per m3)</i></small>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withoutVat?.tariffGas1 }}</small></span>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withVat?.tariffGas1 }}</small></span>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withTotalVat?.tariffGas1 }}</small></span>
       </a-col>
       <a-col
        :xs="12"
       >
        Leveringstarief (G2) <small><i>(per m3)</i></small>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withoutVat?.tariffGas2 }}</small></span>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withVat?.tariffGas2 }}</small></span>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withTotalVat?.tariffGas2 }}</small></span>
       </a-col>
       <a-col
        :xs="12"
       >
        Vaste leveringskosten <small><i>(per jaar)</i></small>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withoutVat?.tariffFixedGas }}</small></span>
       </a-col>
       <a-col
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withVat?.tariffFixedGas }}</small></span>
       </a-col>
       <a-col
        :xs="4"
       >
        <span>&nbsp;</span>
       </a-col>
       <a-col
        v-if="calculations.consumerTariff.withoutVat.tariffFixedEnergyReturn"
        :xs="12"
       >
        Vaste terugleveringskosten <small><i>(per jaar)</i></small>
       </a-col>
       <a-col
        v-if="calculations.consumerTariff.withoutVat.tariffFixedEnergyReturn"
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withoutVat?.tariffFixedEnergyReturn }}</small></span>
       </a-col>
       <a-col
        v-if="calculations.consumerTariff.withoutVat.tariffFixedEnergyReturn"
        :xs="4"
       >
        <span><small>&euro;{{ calculations?.consumerTariff?.withVat?.tariffFixedEnergyReturn }}</small></span>
       </a-col>
       <a-col
        v-if="calculations.consumerTariff.withoutVat.tariffFixedEnergyReturn"
        :xs="4"
       >
        <span>&nbsp;</span>
       </a-col>
      </a-row>
     </div>
    </a-collapse-panel>
   </a-collapse>
   <a-row
    class="mt-3"
    style="background-color:#000000;color:#ffffff;padding: 10px 10px;border-radius: 4px;"
   >
    <a-col
     :xs="16"
     style="padding-top:5px"
    >
     <strong>Jaarlijkse kosten ({{ type === 'CONSUMER' || calculations.product.isSoho === true ? 'incl.' : 'excl.' }}
      btw)</strong>
    </a-col>
    <a-col
     :xs="8"
     class="text-right"
     style="padding-top:5px"
    >
     <span v-if="type === 'CONSUMER' || calculations.product.isSoho === true"><strong>{{
       new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR'
       }).format(parseFloat(calculations.calculation.summary.totalWithVat))
      }}</strong></span>
     <span v-if="type === 'BUSINESS' && calculations.product.isSoho === false"><strong>{{
       new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR'
       }).format(parseFloat(calculations.calculation.summary.total))
      }}</strong></span>
    </a-col>
    <a-col
     :xs="16"
     style="padding-top:5px"
    >
     <strong>Maandelijkse kosten ({{ type === 'CONSUMER' || calculations.product.isSoho === true ? 'incl.' : 'excl.' }}
      btw)</strong>
    </a-col>
    <a-col
     :xs="8"
     class="text-right"
     style="padding-top:5px"
    >
     <span v-if="type === 'CONSUMER' || calculations.product.isSoho === true"><strong>{{
       new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR'
       }).format(parseFloat(parseFloat(calculations.calculation.summary.totalWithVat) / 12))
      }}</strong></span>
     <span v-if="type === 'BUSINESS' && calculations.product.isSoho === false"><strong>{{
       new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR'
       }).format(parseFloat(parseFloat(calculations.calculation.summary.total) / 12))
      }}</strong></span>
    </a-col>
    <a-col
     :xs="16"
     style="padding-top:5px"
    >
     <strong>btw</strong>
    </a-col>
    <a-col
     :xs="8"
     class="text-right"
     style="padding-top:5px"
    >
     <strong>{{
       new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR'
       }).format((parseFloat(calculations.calculation.summary.vat) / 12))
      }}</strong>
    </a-col>
   </a-row>
   <a-row
    v-if="calculations.calculation.hasPriceCap"
    class="mt-3"
    style="background-color:#000000;color:#ffffff;padding: 10px 10px;border-radius: 4px;"
   >
    <a-col
     :xs="16"
     style="padding-top:5px"
    >
     <strong>Geschat jaarlijkse kosten met prijsplafond
      ({{ type === 'CONSUMER' || calculations.product.isSoho === true ? 'incl.' : 'excl.' }}
      btw)</strong>
    </a-col>
    <a-col
     :xs="8"
     class="text-right"
     style="padding-top:5px"
    >
     <span v-if="type === 'CONSUMER' || calculations.product.isSoho === true"><strong>{{
       new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR'
       }).format(parseFloat(calculations.calculation.summaryWithPriceCap.totalWithVat))
      }}</strong></span>
     <span v-if="type === 'BUSINESS' && calculations.product.isSoho === false"><strong>{{
       new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR'
       }).format(parseFloat(calculations.calculation.summaryWithPriceCap.total))
      }}</strong></span>
    </a-col>
    <a-col
     :xs="16"
     style="padding-top:5px"
    >
     <strong>Geschat maandelijkse kosten met prijsplafond
      ({{ type === 'CONSUMER' || calculations.product.isSoho === true ? 'incl.' : 'excl.' }}
      btw)</strong>
    </a-col>
    <a-col
     :xs="8"
     class="text-right"
     style="padding-top:5px"
    >
     <span v-if="type === 'CONSUMER' || calculations.product.isSoho === true"><strong>{{
       new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR'
       }).format(parseFloat(calculations.calculation.summaryWithPriceCap.totalWithVat / 12))
      }}</strong></span>
     <span v-if="type === 'BUSINESS' && calculations.product.isSoho === false"><strong>{{
       new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR'
       }).format(parseFloat(calculations.calculation.summaryWithPriceCap.total / 12))
      }}</strong></span>
    </a-col>
   </a-row>
  </sdCards>
 </Suspense>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from "vue-types";

export default defineComponent({
 name: 'Aom',
 components: {},
 data() {
 },
 props: {
  calculations: VueTypes.object,
  type: VueTypes.oneOf(['BUSINESS', 'CONSUMER']).def('CONSUMER'),
  loading: VueTypes.bool.def(true),
 },
});
</script>
