<template>
  <UserBioBox>
    <sdCards title="Contactgegevens">
      <a-row v-if="!order || !order.relation">
        <a-col :xs="24">
          <div class="user-info">
            <a-skeleton active/>
          </div>
        </a-col>
      </a-row>
      <a-row v-else>
        <a-col :md="12" :xs="24">
          <div class="user-info">
            <ul class="user-info__contact">
              <li>
                <template v-if="order.relation.type==='CONSUMER'">
                  <sdFeatherIcons type="user" size="14"/>
                  <span>{{
                      order.relation.gender === 'MALE' ? 'Dhr.' : ''
                    }}{{
                      order.relation.gender === 'FEMALE' ? 'Mevr.' : ''
                    }} {{
                      order.relation.firstName
                    }}{{
                      order.relation.middleName && order.relation.middleName !== '' ? ` ${order.relation.middleName}` : ''
                    }} {{ order.relation.lastName }}</span>
                </template>
                <template v-if="order.relation.type==='BUSINESS'">
                  <sdFeatherIcons type="briefcase" size="14"/>
                  <span>{{ order.relation.companyName }}<br/>{{
                      order.relation.companyCocNumber
                    }}</span>
                </template>
              </li>
              <li>
                <sdFeatherIcons type="home" size="14"/>
                <span>{{ order.relation.street }} {{
                    order.relation.houseNumber
                  }}{{
                    order.relation.houseNumberAddition && order.relation.houseNumberAddition !== '' ? order.relation.houseNumberAddition : ''
                  }}<br/>{{ order.relation.postalCode }} {{
                    order.relation.city
                  }} {{ order.relation.country === 'NL' ? 'Nederland' : '' }}</span>
              </li>
              <li>
                <sdFeatherIcons type="mail" size="14"/>
                <span>{{ order.relation.email }}</span>
              </li>
              <li>
                <sdFeatherIcons type="phone" size="14"/>
                <span>{{ order.relation.phoneNumber }}</span></li>
            </ul>
          </div>
        </a-col>
        <a-col :md="12" :xs="24">
          <div class="user-info">
            <ul class="user-info__contact">
              <li>
                <sdFeatherIcons type="calendar" size="14"/>
                <span>{{ moment(order.relation.birthDate).format('DD-MM-YYYY') }}</span></li>
              <li>
                <sdFeatherIcons type="credit-card" size="14"/>
                <span>{{ order.relation.bankAccountNumber }}<br/>{{ order.relation.debtorName }}</span>
              </li>
            </ul>
          </div>
        </a-col>
      </a-row>
    </sdCards>
  </UserBioBox>
</template>
<script>
import {UserBioBox} from './style';
import {defineComponent} from 'vue';
import VueTypes from "vue-types";
import moment from "moment";

const RelationSummaryCard = defineComponent({
  name: 'RelationSummaryCard',
  components: {UserBioBox},
  props: {
    order: VueTypes.object.def(null),
  },
  setup() {
    return {moment};
  },
});

export default RelationSummaryCard;
</script>
