<template>
 <div v-if="isLoader" class="spin">
  <a-spin/>
 </div>
 <ProductCard v-else style="margin-bottom: 30px; height:100%">
  <sdModal :onCancel="handleAomCancel" :visible="aomVisible" title="" type="primary">
   <ModalMain>
    <Aom :calculations="data" :type="type"/>
   </ModalMain>
  </sdModal>
  <figure style="text-align: center">
   <img
    v-if="data.product && data.product.imageFileURL"
    :src="data.product.imageFileURL"
    alt=""
    style="padding:40px; max-width: 250px"
   />
   <img v-else
        :src="require('@/static/img/avatar/profileImage.png')"
        alt=""
   />
  </figure>
  <figcaption>
   <sdHeading as="h5" class="product-single-title" style="text-align: center">
    {{ data.product.name }}
   </sdHeading>
   <!--      <p class="product-single-price" style="text-align: center">-->
   <!--        <span v-if="data.product.uspText" class="product-single-price__new" v-html="data.product.uspText"></span>-->
   <!--      </p>-->
   <p v-if="!data.product.isProxy" class="product-single-price" style="text-align: center">
        <span v-if="type === 'CONSUMER'" class="product-single-price__new"><InfoCircleOutlined
         style="font-size: 16px;padding-right:5px" @click="showAom(data)"/> <span
         style="font-size: 1.1rem;font-weight: 500">&euro; {{
          parseFloat(data.calculation.summary.totalWithVat / 12).toFixed(2)
         }}</span> <small>per maand</small></span>
    <!--        <span v-if="type === 'CONSUMER'" class="product-single-price__new"><span style="font-size: 0.8rem;font-weight: 500">&euro; {{ parseFloat(data.calculation.summary.totalWithVat).toFixed(2) }}</span> <small>per jaar</small></span>-->
    <span v-if="type === 'BUSINESS'" class="product-single-price__new"><InfoCircleOutlined
     style="font-size: 16px;padding-right:5px" @click="showAom(data)"/> <span
     style="font-size: 1.1rem;font-weight: 500">&euro; {{
      parseFloat(data.calculation.summary.total / 12).toFixed(2)
     }}</span> <small>per maand</small></span>
    <!--        <span v-if="type === 'BUSINESS'" class="product-single-price__new"><span style="font-size: 0.8rem;font-weight: 500">&euro; {{ parseFloat(data.calculation.summary.total).toFixed(2) }}</span> <small>per jaar</small></span>-->
    <br/><span v-if="type === 'CONSUMER'" class="text-center"><small class="text-center" style="color:#9299B8">inclusief BTW</small></span>
    <span v-if="type === 'BUSINESS'" class="text-center"><small class="text-center"
                                                                style="color:#9299B8">Exclusief BTW</small></span>
   </p>
   <div class="product-single-action"
        style="position: absolute;right: 0;bottom: 0;padding: 0;margin: 0;display:flex;width:100%">
    <sdButton v-if="data.available" class="btn-cart" size="small" style="width:100% !important;" type="primary"
              @click="handleSelectProduct">
     <sdFeatherIcons size="14" type="shopping-bag"/>
     <span>Selecteren</span>
    </sdButton>
   </div>
  </figcaption>
  <div v-if="!data.available"
       style="border-radius: 10px;top:0;left:0;position: absolute;background: rgba(0,0,0,0.75);z-index: 9;width: 100%;height: 100%;text-align: center;vertical-align: middle;">
   <div style="top: 50%;position: relative;">{{ data.unavailableReason }}</div>
  </div>
 </ProductCard>
</template>
<script>
import {ProductCard} from '../Style';
import PropTypes from 'vue-types';
import VueTypes from 'vue-types';
import {computed, defineComponent, ref, toRefs} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import Aom from "../../../components/aom/Aom";

const {InfoCircleOutlined} = require("@ant-design/icons-vue");

const ProductCards = defineComponent({
 name: 'ProductCards',
 components: {ProductCard, InfoCircleOutlined, Aom},
 props: {
  data: PropTypes.object,
  type: VueTypes.oneOf(['CONSUMER', 'BUSINESS']).def('CONSUMER'),
 },
 methods: {
  handleSelectProduct() {
   this.$emit('set-product-id')
  },
 },
 setup(props) {
  const {dispatch, state} = useStore();
  const isLoader = computed(() => state.order.loadingOrdersOfferFlowProductCalculations);
  const {data} = toRefs(props);
  const renderData = computed(() => data.value);
  const {matched} = useRoute();
  const aomVisible = ref(false)
  const showAom = () => {
   aomVisible.value = true;
  }

  const handleAomCancel = async () => {
   aomVisible.value = false;
  }

  return {
   handleAomCancel,
   aomVisible,
   showAom,
   dispatch,
   renderData,
   isLoader,
   matched,
  };
 },
});

export default ProductCards;
</script>
